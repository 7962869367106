import { Component, Input, OnInit } from '@angular/core';
import { ExplanationStep } from 'src/app/core/components/how-does-it-work/how-does-it-work.component';

@Component({
  selector: 'app-explanation-step',
  templateUrl: './explanation-step.component.html',
  styleUrls: ['./explanation-step.component.scss']
})
export class ExplanationStepComponent implements OnInit {

  @Input()
  explanationStep: ExplanationStep;

  constructor() {
    // Do nothing
  }

  ngOnInit(): void {
    // Do nothing
  }

  getExplanationStepCssClass() {
    if(this.explanationStep.index == 0){
      return "explanation-step-1";  
    }
    if(this.explanationStep.index == 1){
      return "explanation-step-2";  
    }
    if(this.explanationStep.index == 2){
      return "explanation-step-3";  
    }
    return "";
  }

}
