import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FlexLayoutModule } from '@angular/flex-layout';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthConfigModule } from './auth/auth-config.module';
import { MaterialModule } from './material-module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { extModules } from './build-specifics';
import { BottomMenuComponent } from './core/components/bottom-menu/bottom-menu.component';
import { UnauthorizedComponent } from './presentation/pages/security/unauthorized/unauthorized.component';
import { HomeComponent } from './presentation/pages/home/home.component';
import { TrimValueAccessorDirective } from './core/directives/trim.directive';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { appReducer } from './stores/global/app.reducer';
import { ConfirmationDialogComponent } from './core/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { CountrySelectorComponent } from './core/components/country-selector/country-selector.component';
import { ProductBannerComponent } from './core/components/product-banner/product-banner.component';
import { FeatureGridComponent } from './core/components/feature-grid/feature-grid.component';
import { FeatureGridTileComponent } from './core/components/feature-grid-tile/feature-grid-tile.component';
import { ExplanationBannerComponent } from './core/components/explanation-banner/explanation-banner.component';
import { ExplanationStepComponent } from './core/components/explanation-step/explanation-step.component';
import { HowDoesItWorkComponent } from './core/components/how-does-it-work/how-does-it-work.component';
import { NavigationComponent } from './core/components/navigation/navigation.component';
import { AcknowledgeDialogComponent } from './core/components/dialogs/acknowledge-dialog/acknowledge-dialog.component';
import { SpinnerDialogComponent } from './core/components/dialogs/spinner-dialog/spinner-dialog.component';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { NgxEchartsModule } from 'ngx-echarts';
import { OidcCallbackComponent } from './presentation/pages/security/oidc-callback/oidc-callback.component';
import { AboutComponent } from './presentation/pages/about/about.component';
import { HelpCenterComponent } from './presentation/pages/help-center/help-center.component';
import { FaqPageComponent } from './presentation/pages/faq-page/faq-page.component';
import { ResourcesMenuComponent } from './core/components/resources-menu/resources-menu.component';
import { PrivacyPolicyPageComponent } from './presentation/pages/privacy-policy/privacy-policy-page.component';
import { UserTermsPageComponent } from './presentation/pages/user-terms/user-terms-page.component';
import { ClimatePledgeComponent } from './presentation/pages/climate-pledge/climate-pledge.component';
import { ContactComponent } from './presentation/pages/contact/contact.component';
import { ProductBannerChefsSpecialComponent } from './core/components/product-banner-chefs-special/product-banner-chefs-special.component';
import { ProductBannerBreakfastComponent } from './core/components/product-banner-breakfast/product-banner-breakfast.component';
import { ProductBannerLunchComponent } from './core/components/product-banner-lunch/product-banner-lunch.component';
import { ProductBannerDinnerComponent } from './core/components/product-banner-dinner/product-banner-dinner.component';
import { ProductBannerHauteCuisineComponent } from './core/components/product-banner-haute-cuisine/product-banner-haute-cuisine.component';
import { ProductBannerPreparedComponent } from './core/components/product-banner-prepared/product-banner-prepared.component';
import { ProductBannerLevelUpComponent } from './core/components/product-banner-prepared-level-up/product-banner-prepared-level-up.component';
import { ProductBannerBuildConnectionsComponent } from './core/components/product-banner-prepared-build-connections/product-banner-prepared-build-connections.component';
import { ProductBannerKycComponent } from './core/components/product-banner-prepared-kyc/product-banner-prepared-kyc.component';
import { ProductBannerMadeForAnimalsComponent } from './core/components/product-banner-made-for-animals/product-banner-made-for-animals.component';
import { ProductBannerTakeOrderComponent } from './core/components/product-banner-take-order/product-banner-take-order.component';
import { ProductBannerLetsStartSavingComponent } from './core/components/product-banner-lets-start-saving/product-banner-lets-start-saving.component';
import { ShoutOutsComponent } from './core/components/shout-outs/shout-outs.component';
import { ShoutOutsPageComponent } from './presentation/pages/shout-outs-page/shout-outs-page.component';
import { PlanetBPageComponent } from './presentation/pages/planet-b-page/planet-b-page.component';
import { AccessibilityComponent } from './presentation/pages/accessibility/accessibility.component';
import { PrivacyPolicyComponent } from './core/components/privacy-policy/privacy-policy.component';
import { UserTermsComponent } from './core/components/user-terms/user-terms.component';
import { FaqComponent } from './core/components/faq/faq.component';

@NgModule({
  declarations: [
    AppComponent,
    BottomMenuComponent,
    UnauthorizedComponent,
    HomeComponent,
    TrimValueAccessorDirective,
    ConfirmationDialogComponent,
    CountrySelectorComponent,
    ProductBannerComponent,
    FeatureGridComponent,
    FeatureGridTileComponent,
    ExplanationBannerComponent,
    ExplanationStepComponent,
    HowDoesItWorkComponent,
    NavigationComponent,
    AcknowledgeDialogComponent,
    SpinnerDialogComponent,
    OidcCallbackComponent,
    AboutComponent,
    HelpCenterComponent,
    FaqPageComponent,
    ResourcesMenuComponent,
    PrivacyPolicyPageComponent,
    UserTermsPageComponent,
    ClimatePledgeComponent,
    ContactComponent,
    ProductBannerChefsSpecialComponent,
    ProductBannerBreakfastComponent,
    ProductBannerLunchComponent,
    ProductBannerDinnerComponent,
    ProductBannerHauteCuisineComponent,
    ProductBannerPreparedComponent,
    ProductBannerLevelUpComponent,
    ProductBannerBuildConnectionsComponent,
    ProductBannerKycComponent,
    ProductBannerMadeForAnimalsComponent,
    ProductBannerTakeOrderComponent,
    ProductBannerLetsStartSavingComponent,
    ShoutOutsComponent,
    ShoutOutsPageComponent,
    PlanetBPageComponent,
    AccessibilityComponent,
    PrivacyPolicyComponent,
    UserTermsComponent,
    FaqComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AuthConfigModule,
    MaterialModule,
    FormsModule,
    FlexLayoutModule,
    StoreModule.forRoot({ appReducerKey: appReducer }),
    EffectsModule.forRoot([]),
    extModules,
    FontAwesomeModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxMatIntlTelInputComponent,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
  ],
  providers: [
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { floatLabel: 'always' } },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
