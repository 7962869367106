import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';

export interface ExplanationStep {
  index: number;
  title: string;
  subtitle: string;
  description: string;
  imageUrl: string;
  showPricingAction: boolean;
  forBusiness : boolean;
}

const EXPLANATION_STEPS : ExplanationStep[] = [
  // For your Customers
  { forBusiness: false, index: 0, title: "Starters", subtitle: "Get the Planet VG app", description: "Get the Planet VG app. Create an account. Become a club member.", showPricingAction: false, imageUrl:"/assets/img/how-it-works/how-it-works-customers-starters.webp" },
  { forBusiness: false, index: 1, title: "Mains", subtitle: "Claim Vouchers and Collect Stamps", description: "Receive vouchers when you claimed them or collected enough stamps. Club members only.", showPricingAction: false, imageUrl:"/assets/img/how-it-works/how-it-works-customers-mains.webp" },
  { forBusiness: false, index: 2, title: "Desserts", subtitle: "Redeem Rewards", description: "Redeem your voucher. Your favorite club appreciates you. Enjoy your reward!", showPricingAction: true, imageUrl:"/assets/img/how-it-works/how-it-works-customers-desserts.webp" },
];

@Component({
  selector: 'app-how-does-it-work',
  templateUrl: './how-does-it-work.component.html',
  styleUrls: ['./how-does-it-work.component.scss']
})
export class HowDoesItWorkComponent implements OnInit {
  private _isLarge: boolean
  private _isMedium: boolean;
  private _isSmall: boolean;
  private _isXSmall : boolean;

  constructor(private responsive: BreakpointObserver) {
    // Do nothing
  }

  ngOnInit(): void {
    // Do nothing
    this.responsive.observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large]).subscribe(
      result => {
        const breakpoints = result.breakpoints;

        this._isLarge = false;
        this._isMedium = false;
        this._isSmall = false;
        this._isXSmall = false;

        if (breakpoints[Breakpoints.Medium]) {
          this._isMedium = true;
        }
        else if (breakpoints[Breakpoints.Small]) {
          this._isSmall = true;
        }
        else if (breakpoints[Breakpoints.XSmall]) {
          this._isXSmall = true;
        }
        else {
          this._isLarge = true;
        }
      }
    );
  }

  getExplanationSteps() : ExplanationStep[] {
    return EXPLANATION_STEPS;
  }

  isLarge(): boolean {
    return this._isLarge;
  }

  isMedium(): boolean {
    return this._isMedium;
  }
  isSmall(): boolean{
    return this._isSmall;
  }
  isXSmall(): boolean{
    return this._isXSmall;
  }
}
