<div *ngIf="isXLarge() || isLarge() || isMedium(); else smallToolbar">
  <mat-toolbar class="mat-component-background mat-elevation-z2">
    <mat-toolbar-row>
      <div>
        <span>
          <button mat-button routerLink="/home" class="image-button">
            <img
              src="/assets/img/planetvg-banner-no-slogan.png"
              width="150"
              alt="planetvg-banner"
            />
          </button>
        </span>
      </div>
      <div
        [ngClass]="{
          'container-buttons-large': isXLarge() || isLarge(),
          'container-buttons-medium': isMedium()
        }"
      >
        <ul
          [ngClass]="{
            'toolbar-row-buttons-large': isXLarge() || isLarge(),
            'toolbar-row-buttons-medium': isMedium()
          }"
        >
          <li>
            <div>
              <span
                ><a
                  href="#"
                  mat-button
                  color="primary"
                  routerLink="/about"
                  class="topmenu-item"
                  >About</a
                ></span
              >
            </div>
          </li>
        </ul>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
</div>

<ng-template #smallToolbar>
  <div *ngIf="isSmall(); else xSmallToolbar">
    <mat-toolbar class="mat-component-background mat-elevation-z2">
      <mat-toolbar-row>
        <div>
          <span>
            <button mat-button routerLink="/home" class="image-button">
              <img
                src="/assets/img/planetvg-banner-no-slogan.png"
                width="150"
                alt="planetvg-banner"
              />
            </button>
          </span>
        </div>
        <div class="container-buttons-small">
          <ul class="toolbar-row-buttons-small">
            <li>
              <div>
                <button mat-icon-button [matMenuTriggerFor]="smallPublicMenu">
                  <mat-icon>more_vert</mat-icon>
                </button>
              </div>
            </li>
          </ul>
        </div>
      </mat-toolbar-row>
    </mat-toolbar>
  </div>
</ng-template>

<ng-template #xSmallToolbar>
  <div *ngIf="isXSmall(); else noToolbar">
    <mat-toolbar class="mat-component-background mat-elevation-z2">
      <mat-toolbar-row>
        <div>
          <span>
            <button mat-button routerLink="/home" class="image-button">
              <img
                src="/assets/img/planetvg-banner-no-slogan.png"
                width="150"
                alt="planetvg-banner"
              />
            </button>
          </span>
        </div>
        <div class="container-buttons-small">
          <ul class="toolbar-row-buttons-xsmall">
            <li>
              <div>
                <button mat-icon-button [matMenuTriggerFor]="xSmallPublicMenu">
                  <mat-icon>more_vert</mat-icon>
                </button>
              </div>
            </li>
          </ul>
        </div>
      </mat-toolbar-row>
    </mat-toolbar>
  </div>
</ng-template>

<ng-template #noToolbar>
  <p>No Toolbar could be dislayed.</p>
</ng-template>

<mat-sidenav-container>
  <mat-sidenav
    #drawer
    mode="side"
    [opened]="(isAuthenticated$ | async)?.isAuthenticated"
    role="navigation"
    class="mat-component-background mat-elevation-z2"
  >
  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet (activate)="scrollTop($event)"></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>

<ng-template #noAuth>
  <button mat-raised-button (click)="login()">
    <mat-icon><span class="material-icons">login</span></mat-icon
    >Enter Portal
  </button>
</ng-template>

<mat-menu #smallPublicMenu="matMenu" class="mat-component-background">
  <ng-template matMenuContent>
    <mat-nav-list>
      <a mat-list-item routerLink="/about"
        ><span class="mat-list-item">About</span></a
      >
    </mat-nav-list>
  </ng-template>
</mat-menu>

<mat-menu #xSmallPublicMenu="matMenu" class="mat-component-background">
  <ng-template matMenuContent>
    <mat-nav-list>
      <a mat-list-item routerLink="/about"
        ><span class="mat-list-item">About</span></a
      >
    </mat-nav-list>
  </ng-template>
</mat-menu>
