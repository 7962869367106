<div
  [ngClass]="{
    'container-xlarge': isXLarge(),
    'container-large': isLarge(),
    'container-medium': isMedium(),
    'container-small': isSmall(),
    'container-xsmall': isXSmall()
  }"
>
  <div class="container-product-banner">
    <app-product-banner></app-product-banner>
  </div>

  <mat-divider></mat-divider>

  <div class="container-product-banner">
    <app-how-does-it-work></app-how-does-it-work>
  </div>

  <div class="container-divider">
    <img
      src="/assets/img/planetvg_icon.png"
      width="150px"
      alt="planetvg-logo"
    />
  </div>
  <mat-divider></mat-divider>

  <div class="container-product-banner container-product-banner-extra-gap">
    <app-product-banner-made-for-animals></app-product-banner-made-for-animals>
  </div>
  <div
    [ngClass]="{
      'container-card-xlarge': isXLarge(),
      'container-card-large': isLarge(),
      'container-card-medium': isMedium(),
      'container-card-small': isSmall(),
      'container-card-xsmall': isXSmall()
    }"
  ></div>
</div>
<app-bottom-menu></app-bottom-menu>
